import React, { PropsWithChildren } from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Link, Typography, Skeleton } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { FHCurrency } from "../FHCurrency";
import { FHTooltip } from "../FHTooltip";
import { useActiveExperimentsStateSelector } from "@/store/selectors";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  footerRoot: {
    backgroundColor: theme.palette.grey[800],
    padding: theme.spacing(1, 2),
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  preDiscountTotalAmount: {
    display: "inline",
    color: theme.palette.grey[400],
    textDecoration: "line-through",
    marginRight: theme.spacing(0.5),
  },
  price: {
    display: "inline",
  },
  lineItem: {
    padding: theme.spacing(0.5, 0),
    "& p, & a": {
      fontSize: "14px",
    },
  },
  totalLabel: {
    textTransform: "uppercase",
  },
  tooltipIcon: {
    height: "20px",
    width: "20px",
    marginLeft: theme.spacing(0.5),
    color: theme.palette.grey[200],
    verticalAlign: "middle",
    marginTop: "-3px",
  },
  balanceDue: {
    lineHeight: "17px",
    color: theme.palette.text.primary,
    textDecorationColor: theme.palette.text.primary,
  },
  totalDivider: {
    border: 0,
    marginTop: "1px",
    marginBottom: "5px",
    borderBottom: `2px solid ${theme.palette.divider}`,
  },
  remainingDepositText: {
    "& strong": {
      marginRight: theme.spacing(0.4),
    },
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexWrap: "wrap",
    },
    "& span": {
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
  },
  priceLarge: {
    fontSize: 24,
  },
}));

type BasketFooterProps = {
  balanceDueAmount?: number;
  balanceDueDateISO?: string | null;
  balanceDueLabel?: string;
  depositAmount?: number;
  depositInfoIcon?: JSX.Element;
  depositLabel?: string;
  depositOverrideLiableAmount?: number;
  depositTooltip?: string;
  loading?: boolean;
  paymentAmount?: number;
  paymentLabel?: string;
  preDiscountTotalAmount?: number;
  promoAmount?: number;
  promoLabel?: string;
  totalAmount?: number;
  totalLabel?: string;
  remainingDepositLabel?: string;
  dueOnLabel?: string;
  remainingDeposit?: number;
  remainingBalanceLabel?: string;
  remainingBalance?: number;
  remainingDepositDueDate?: string | null;
  remainingBalanceDueDate?: string | null;
  isPostBookingJourney?: boolean;
  largerTotalPrice?: boolean;
  hideFooter?: boolean;
};

const defaultInfoIcon = () => {
  const classes = useStyles();

  return <InfoIcon className={classes.tooltipIcon} />;
};

export const BasketFooter: React.FC<BasketFooterProps> = ({
  balanceDueAmount = 0,
  balanceDueLabel = "Balance due",
  balanceDueDateISO,
  depositAmount,
  depositInfoIcon = defaultInfoIcon(),
  depositLabel = "Deposit",
  depositOverrideLiableAmount = 0,
  depositTooltip,
  loading = false,
  preDiscountTotalAmount,
  promoAmount = 0,
  promoLabel = "Promo/discount",
  paymentAmount = 0,
  paymentLabel = "Payments",
  totalAmount = 0,
  totalLabel = "Total",
  remainingDepositLabel,
  dueOnLabel,
  remainingDeposit,
  remainingBalanceLabel,
  remainingBalance,
  remainingDepositDueDate,
  remainingBalanceDueDate,
  isPostBookingJourney,
  largerTotalPrice,
  hideFooter = true,
}: PropsWithChildren<BasketFooterProps>) => {
  const classes = useStyles();
  const balanceDueMinusDeposit = balanceDueAmount - (depositAmount ?? 0);
  const { activeExperiments } = useActiveExperimentsStateSelector();

  const getDepositTooltipMessage = () => {
    const depositIdentifier = "##DEPOSIT##";
    if (depositTooltip?.includes(depositIdentifier)) {
      const beforeDepositAmount = depositTooltip?.substring(
        0,
        depositTooltip.indexOf(depositIdentifier),
      );
      const afterDepositAmount = depositTooltip?.substring(
        depositTooltip.indexOf(depositIdentifier) + 11,
        depositTooltip.length,
      );
      return (
        <Typography variant="body2" align="center">
          {beforeDepositAmount}{" "}
          <strong>
            <FHCurrency>{depositOverrideLiableAmount}</FHCurrency>
          </strong>{" "}
          {afterDepositAmount}
        </Typography>
      );
    } else {
      return (
        <Typography variant="body2" align="center">
          {depositTooltip}
        </Typography>
      );
    }
  };

  const showDividerUnderTotal =
    (!!depositAmount && depositAmount > 0) ||
    paymentAmount > 0 ||
    (balanceDueMinusDeposit > 0 && !!balanceDueDateISO) ||
    promoAmount > 0;

  return (
    <div className={classes.footerRoot}>
      {loading ? (
        <>
          <Skeleton height={18} />
          <Skeleton height={18} />
          <Skeleton height={18} />
        </>
      ) : (
        <>
          {promoAmount > 0 && (
            <Grid container xs={12} className={classes.lineItem}>
              <Grid item xs>
                <Typography variant="body2">{promoLabel}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">
                  -<FHCurrency>{promoAmount}</FHCurrency>
                </Typography>
              </Grid>
            </Grid>
          )}
          {hideFooter && (
            <>
              {Number(depositAmount) >= 0 && (
                <Grid container xs={12} className={classes.lineItem}>
                  <Grid item xs>
                    <Typography variant="body2">
                      {depositLabel}
                      {!!depositTooltip &&
                        !!depositOverrideLiableAmount &&
                        depositOverrideLiableAmount > 0 && (
                          <FHTooltip
                            title={getDepositTooltipMessage()}
                            placement="top"
                            arrow
                          >
                            {depositInfoIcon}
                          </FHTooltip>
                        )}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">
                      <FHCurrency>{depositAmount}</FHCurrency>
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {paymentAmount > 0 && (
                <Grid container xs={12} className={classes.lineItem}>
                  <Grid item xs>
                    <Typography variant="body2">
                      <strong>{paymentLabel}</strong>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">
                      <strong>
                        <FHCurrency>{paymentAmount}</FHCurrency>
                      </strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <hr className={classes.totalDivider} />
                  </Grid>
                </Grid>
              )}
              {remainingDepositLabel && remainingDeposit && (
                <Grid container xs={12} className={classes.lineItem}>
                  <Grid item xs>
                    <Typography className={classes.remainingDepositText}>
                      <strong>{remainingDepositLabel}</strong>
                      {dueOnLabel}{" "}
                      {remainingDepositDueDate && (
                        <span>{remainingDepositDueDate}</span>
                      )}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">
                      <FHCurrency>{remainingDeposit}</FHCurrency>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <hr className={classes.totalDivider} />
                  </Grid>
                </Grid>
              )}
              {remainingBalanceLabel && remainingBalance && (
                <Grid container xs={12} className={classes.lineItem}>
                  <Grid item xs>
                    <Typography className={classes.remainingDepositText}>
                      <strong>{remainingBalanceLabel}</strong>
                      {dueOnLabel}{" "}
                      {remainingBalanceDueDate && (
                        <span>{remainingBalanceDueDate}</span>
                      )}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">
                      <FHCurrency>{remainingBalance}</FHCurrency>
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {balanceDueMinusDeposit > 0 && !!balanceDueDateISO && (
                <Grid container xs={12} className={classes.lineItem}>
                  <Grid item xs>
                    <Typography variant="body2">
                      <Link
                        className={classes.balanceDue}
                        variant="body1"
                        underline={"none"}
                      >
                        {balanceDueLabel}
                      </Link>{" "}
                      {`(${balanceDueDateISO})`}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">
                      <FHCurrency>{balanceDueMinusDeposit}</FHCurrency>
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {showDividerUnderTotal && (
                <Grid item xs={12}>
                  <hr className={classes.totalDivider} />
                </Grid>
              )}
            </>
          )}

          <Grid container xs={12}>
            <Grid item xs>
              <Typography className={classes.totalLabel}>
                <strong>{totalLabel}</strong>
              </Typography>
            </Grid>
            <Grid item>
              {!!preDiscountTotalAmount && (
                <Typography className={classes.preDiscountTotalAmount}>
                  <strong>
                    <FHCurrency>{preDiscountTotalAmount}</FHCurrency>
                  </strong>
                </Typography>
              )}
              <Typography
                color={
                  !!activeExperiments?.changeTotalPriceColor &&
                  !isPostBookingJourney &&
                  "primary"
                }
                className={classNames(classes.price, {
                  [classes.priceLarge]: largerTotalPrice,
                })}
              >
                <strong>
                  <FHCurrency>{totalAmount}</FHCurrency>
                </strong>
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};
