import React, { PropsWithChildren, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { BasketFooter } from "./Footer";
import FHCard from "../FHCard";
import classNames from "classnames";
import { Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    [theme.breakpoints.down("sm")]: {
      borderRadius: 0,
      boxShadow: "none",
    },
  },
  cardHeader: {
    alignItems: "center",
    padding: theme.spacing(1.4, 2),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1.1, 2),
    },
  },
  button: {
    paddingTop: theme.spacing(1),
  },
  hideCardHeader: {
    overflow: "hidden",
    borderRadius: 6,
  },
  requiredText: {
    fontSize: "14px",
    color: theme.palette.secondary.dark,
    padding: theme.spacing(1, 2, 0),
  },
}));

type FHBasketContainerProps = {
  balanceDueAmount?: number;
  balanceDueLabel?: string;
  balanceDueDateISO?: string | null;
  continueButton?: JSX.Element;
  depositAmount?: number;
  depositLabel?: string;
  depositOverrideLiableAmount?: number;
  depositTooltip?: string;
  isMobile?: boolean;
  loading?: boolean;
  paymentAmount?: number;
  paymentLabel?: string;
  preDiscountTotalAmount?: number;
  promoAmount?: number;
  promoLabel?: string;
  totalAmount?: number;
  totalLabel?: string;
  remainingDepositLabel?: string;
  dueOnLabel?: string;
  remainingDeposit?: number;
  remainingBalanceLabel?: string;
  remainingBalance?: number;
  remainingDepositDueDate?: string | null;
  remainingBalanceDueDate?: string | null;
  requiredText?: string;
  isPostBookingJourney?: boolean;
  largerTotalPrice?: boolean;
  hideFooter?: boolean;
};

export const FHBasketContainer: React.FC<
  PropsWithChildren<FHBasketContainerProps>
> = ({
  balanceDueAmount = 0,
  balanceDueLabel = "Balance due",
  balanceDueDateISO,
  children,
  continueButton,
  depositAmount,
  depositLabel = "Deposit",
  depositOverrideLiableAmount = 0,
  depositTooltip,
  isMobile = false,
  loading = false,
  paymentAmount = 0,
  paymentLabel = "Payments",
  preDiscountTotalAmount,
  promoAmount = 0,
  promoLabel = "Promo/discount",
  totalAmount = 0,
  totalLabel = "Total",
  remainingDepositLabel,
  dueOnLabel,
  remainingDeposit,
  remainingBalanceLabel,
  remainingBalance,
  remainingDepositDueDate,
  remainingBalanceDueDate,
  requiredText,
  isPostBookingJourney,
  largerTotalPrice,
  hideFooter,
}: PropsWithChildren<FHBasketContainerProps>) => {
  const classes = useStyles();

  return (
    <FHCard
      className={classNames(classes.cardRoot, {
        [classes.hideCardHeader]: !isMobile,
      })}
    >
      {children}
      <BasketFooter
        hideFooter={hideFooter}
        balanceDueAmount={balanceDueAmount}
        balanceDueLabel={balanceDueLabel}
        balanceDueDateISO={balanceDueDateISO}
        depositAmount={depositAmount}
        depositLabel={depositLabel}
        depositOverrideLiableAmount={depositOverrideLiableAmount}
        depositTooltip={depositTooltip}
        loading={loading}
        paymentAmount={paymentAmount}
        paymentLabel={paymentLabel}
        preDiscountTotalAmount={preDiscountTotalAmount}
        promoAmount={promoAmount}
        promoLabel={promoLabel}
        totalAmount={totalAmount}
        totalLabel={totalLabel}
        remainingDepositLabel={remainingDepositLabel}
        dueOnLabel={dueOnLabel}
        remainingDeposit={remainingDeposit}
        remainingBalanceLabel={remainingBalanceLabel}
        remainingBalance={remainingBalance}
        remainingDepositDueDate={remainingDepositDueDate}
        remainingBalanceDueDate={remainingBalanceDueDate}
        isPostBookingJourney={isPostBookingJourney}
        largerTotalPrice={largerTotalPrice}
      />
      <>
        {!!requiredText && (
          <Typography className={classes.requiredText}>
            {requiredText}
          </Typography>
        )}
        {continueButton}
      </>
    </FHCard>
  );
};
