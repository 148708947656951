import React, { PropsWithChildren } from "react";
import { makeStyles } from "@mui/styles";
import { Drawer, Grid, Hidden, Typography } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import FHClose from "../FHClose";
import FHLink from "../FHLink";
import { useIsMobile } from "src/hooks/hooks";

type FHDrawerStyleProps = {
  showTitleInDesktop: boolean;
};

const useStyles = makeStyles((theme) => ({
  backDropRoot: {
    backgroundColor: `${theme.palette.text.primary}80`,
  },
  paperRoot: {
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[11],
    width: "90%",
    [theme.breakpoints.up("lg")]: {
      width: "60%",
    },
    [theme.breakpoints.down("md")]: {
      border: "none",
    },
  },
  closeWrap: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1.5, 2),
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1.1, 2),
      minHeight: 54,
    },
    [theme.breakpoints.up("md")]: {
      backgroundColor: "transparent",
      padding: 0,
      position: "absolute",
      right: 40,
      top: 40,
      zIndex: 100,
    },
  },
  closeButton: {
    display: "none",
    padding: theme.spacing(0.5),
    backgroundColor: `${theme.palette.text.primary}cc`,
    borderRadius: theme.shape.borderRadius,
    cursor: "pointer",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
    "& svg": {
      fontSize: 30,
      color: theme.palette.common.white,
    },
  },
  mobileCloseBtn: {
    marginLeft: "auto",
    [theme.breakpoints.up("md")]: {
      display: "none",
      marginTop: 0,
    },
  },
  headerTitle: (props: FHDrawerStyleProps) => ({
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      display: props?.showTitleInDesktop ? "auto" : "none",
    },
  }),
  subTitle: {
    fontSize: 11,
    lineHeight: "11px",
  },
  actions: {
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
    "& button": {
      marginLeft: theme.spacing(1.5),
    },
  },
  backLink: {
    padding: theme.spacing(1, 1, 0),
  },
  restyledTitle: {
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(0.6),
    },
  },
  restyledHeaderContainer: {
    padding: theme.spacing(0.5, 1, 2),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(1),
    },
  },
}));

type Anchors = "left" | "right";

export type FHDrawerProps = {
  children: React.ReactNode;
  open?: boolean;
  onClose?: () => void;
  anchor?: Anchors;
  isShowCloseButton?: boolean;
  modalSubTitle?: string;
  modalTitle?: string;
  closeBtnColor?: "primary" | "secondary";
  closeBtnIcon?: JSX.Element;
  paperHandleScroll?: (event: any) => void;
  useRestyled?: boolean;
};

export const FHDrawer: React.FC<FHDrawerProps> = ({
  children,
  open,
  onClose,
  anchor = "right",
  isShowCloseButton,
  modalTitle,
  modalSubTitle,
  closeBtnColor,
  closeBtnIcon = <CloseRoundedIcon />,
  paperHandleScroll,
  useRestyled = false,
}: PropsWithChildren<FHDrawerProps>) => {
  const classes = useStyles({ showTitleInDesktop: useRestyled });
  const isMobile = useIsMobile();

  return (
    <Drawer
      anchor={anchor}
      open={open}
      onClose={onClose}
      ModalProps={{
        BackdropProps: {
          classes: {
            root: classes.backDropRoot,
          },
        },
      }}
      PaperProps={{
        classes: {
          root: classes.paperRoot,
        },
        onScroll: paperHandleScroll,
      }}
    >
      {useRestyled ? (
        <>
          <Hidden mdUp>
            <FHLink
              title="Back"
              color="secondary"
              onLinkClick={onClose}
              showChevronBeforeText
              isBold
              className={classes.backLink}
            />
          </Hidden>
          <Grid item xs={12} className={classes.restyledHeaderContainer}>
            <Grid container>
              <Grid item>
                <Hidden mdDown>
                  <FHClose
                    color={closeBtnColor}
                    icon={closeBtnIcon}
                    onCloseButtonClick={onClose}
                  />
                </Hidden>
              </Grid>
              <Grid item xs>
                {modalTitle && (
                  <Typography className={classes.restyledTitle} variant="h4">
                    {modalTitle}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          {isShowCloseButton && (
            <div className={classes.closeWrap}>
              {modalTitle && (
                <Grid item xs={12}>
                  <Typography className={classes.headerTitle} variant="h4">
                    {modalTitle}
                  </Typography>
                </Grid>
              )}
              <div className={classes.actions}>
                {modalSubTitle && (
                  <Typography className={classes.subTitle}>
                    <strong>{modalSubTitle}</strong>
                  </Typography>
                )}
                {useRestyled && isMobile ? (
                  <></>
                ) : (
                  <>
                    <FHClose
                      className={classes.mobileCloseBtn}
                      color={closeBtnColor}
                      icon={closeBtnIcon}
                      onCloseButtonClick={onClose}
                    />
                    <div onClick={onClose} className={classes.closeButton}>
                      <CloseRoundedIcon />
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </>
      )}
      {children}
    </Drawer>
  );
};
export default FHDrawer;
