import React, { PropsWithChildren } from "react";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Scrollbars } from "react-custom-scrollbars";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.5, 1.3, 0.5, 2),
    cursor: "pointer",
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  title: {
    textTransform: "uppercase",
    color: theme.palette.success.light,
    fontSize: 10,
    fontFamily: theme.typography.subtitle1.fontFamily,
    fontWeight: 600,
  },
  expandIcon: {
    color: theme.palette.text.primary,
    display: "flex",
    marginLeft: "auto",
    "& svg": {
      fontSize: 30,
    },
  },
  childrenContainer: {
    padding: 0,
  },
}));

export enum BasketSections {
  none = "NONE",
  cabinUpgrades = "CABINUPGRADES",
  extras = "EXTRAS",
}

type BasketSectionProps = {
  expanded: boolean;
  fitToViewPort?: boolean;
  onCloseClick: () => void;
  onExpandClick: () => void;
  title: string;
};

export const BasketSection: React.FC<PropsWithChildren<BasketSectionProps>> = ({
  children,
  expanded = false,
  fitToViewPort = false,
  onCloseClick,
  onExpandClick,
  title,
}: PropsWithChildren<BasketSectionProps>) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div
        className={classes.titleContainer}
        onClick={() => (expanded ? onCloseClick() : onExpandClick())}
      >
        <Typography className={classes.title}>{title}</Typography>
        <div className={classes.expandIcon}>
          {expanded ? <ExpandLess /> : <ExpandMore />}
        </div>
      </div>
      {expanded && (
        <div className={classes.childrenContainer}>
          <Scrollbars
            autoHide={true}
            style={
              fitToViewPort
                ? { height: "calc(100vh - 440px)" }
                : { height: "180px" }
            }
          >
            {children}
          </Scrollbars>
        </div>
      )}
    </div>
  );
};
