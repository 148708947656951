import React, { PropsWithChildren } from "react";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[2],
    borderRadius: theme.shape.borderRadius * 2,
    boxSizing: "border-box",
  },
  standard: {
    padding: theme.spacing(2.5, 3),
  },
  alternate: {
    borderRadius: theme.shape.borderRadius + 3,
    border: `1px solid ${theme.palette.divider}`,
    boxShadow: theme.shadows[12],
  },
}));

export type FHCardProps = {
  children: React.ReactNode;
  height?: number;
  width?: number;
  variant?: "standard" | "alternate";
  className?: string;
};

export const FHCard: React.FC<FHCardProps> = ({
  children,
  height,
  width,
  variant,
  className,
}: PropsWithChildren<FHCardProps>) => {
  const classes = useStyles();

  return (
    <div
      style={{ height: height, width: width }}
      className={classNames(classes.root, className, {
        [classes.standard]: variant === "standard",
        [classes.alternate]: variant === "alternate",
      })}
    >
      {children}
    </div>
  );
};
export default FHCard;
