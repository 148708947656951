import { ClickAwayListener, Tooltip, TooltipProps } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
import { useIsMobile } from "../../../hooks/hooks";

type FHTooltipStyleProps = {
  width?: string | number;
};

const useStyles = makeStyles((theme) => ({
  popper: {
    zIndex: 5006,
  },
  tooltip: (props: FHTooltipStyleProps) => ({
    width: props.width ? props.width : 210,
    fontSize: theme.typography.body2.fontSize,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[6],
  }),
  arrow: {
    width: "3em",
    height: "1.5em",
    marginTop: "-1.5em",
    "&::before": {
      backgroundColor: theme.palette.background.default,
      boxShadow: theme.shadows[7],
    },
  },
  tooltipPlacementBottom: {
    "& $arrow": {
      marginTop: "-1.5em !important",
      marginLeft: "16px !important",
    },
  },
  tooltipPlacementTop: {
    "& $arrow": {
      marginBottom: "-1.5em !important",
      marginLeft: "-5px !important",
      "&::before": {
        boxShadow: theme.shadows[8],
      },
    },
  },
}));

export type FHTooltipProps = { leaveDelayValue?: number } & TooltipProps &
  FHTooltipStyleProps;

export const FHTooltip: React.FC<FHTooltipProps> = ({
  children,
  leaveDelayValue,
  width,
  ...props
}: FHTooltipProps) => {
  const classes = useStyles({ width });
  const [open, setOpen] = React.useState(false);
  const isMobile = useIsMobile();

  useEffect(() => {
    if (open && isMobile) {
      setTimeout(() => {
        setOpen(false);
      }, leaveDelayValue || 6000);
    }
  }, [open]);

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Tooltip
        {...props}
        classes={{
          popper: classes.popper,
          tooltip: classes.tooltip,
          arrow: classes.arrow,
          tooltipPlacementBottom: classes.tooltipPlacementBottom,
          tooltipPlacementTop: classes.tooltipPlacementTop,
        }}
        onClose={() => setOpen(false)}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
      >
        <span onMouseOver={() => setOpen(true)} onClick={() => setOpen(true)}>
          {children}
        </span>
      </Tooltip>
    </ClickAwayListener>
  );
};

export default FHTooltip;
