import React, { PropsWithChildren, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useIsMobile, useIsTouchEnabled } from "../../../hooks/hooks";
import { generateResponsiveImgUrlFromCloudflare } from "../../Utils";
import { Grid, Typography } from "@mui/material";
import { BasketItemImage } from "./BasketItemImage";
import { FHCurrency } from "../FHCurrency";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  description: {
    fontSize: "13px",
  },
  title: {
    fontSize: "15px",
  },
  tag: {
    fontSize: "13px",
    color: theme.palette.primary.main,
  },
  preDiscountValue: {
    display: "inline",
    color: theme.palette.grey[400],
    textDecoration: "line-through",
    marginRight: theme.spacing(0.5),
    fontSize: "13px",
  },
  value: {
    fontSize: "13px",
    display: "inline",
  },
  basketItem: {
    padding: theme.spacing(1.5, 0),
    borderBottom: `2px solid ${theme.palette.divider}`,
  },
  removeCabinImage: {
    padding: theme.spacing(1.6, 0),
  },
  basketItemContainer: {
    padding: theme.spacing(0, 2),
    position: "relative",
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: 0,
      left: "50%",
      height: 2,
      width: 10,
      marginLeft: -5,
      backgroundColor: theme.palette.primary.light,
      opacity: 0,
      transition: "all 0.5s",
    },
    "&:after": {
      top: "auto",
      bottom: 0,
    },
    "&:hover": {
      backgroundColor: `${theme.palette.primary.light}0A`,
      "&:before, &:after": {
        left: 0,
        right: 0,
        width: "100%",
        marginLeft: 0,
        opacity: 1,
      },
    },
    "&:last-child": {
      "& $extraOptionItem": {
        borderBottom: "none",
      },
    },
  },
}));

type BasketItemProps = {
  description?: JSX.Element;
  id: string;
  imageUrl?: string;
  onRemove?: () => void;
  preDiscountValue?: number;
  subtitle?: string;
  tag?: string;
  title?: string;
  value?: number;
  removeImagesExtrasCabin?: boolean;
};

export const BasketItem: React.FC<BasketItemProps> = ({
  description,
  id,
  imageUrl,
  onRemove,
  preDiscountValue,
  tag,
  title,
  subtitle,
  value,
  removeImagesExtrasCabin,
}: PropsWithChildren<BasketItemProps>) => {
  const classes = useStyles();
  const isMobile = useIsMobile();
  const isTouchEnabled = useIsTouchEnabled();
  const [hover, setHover] = useState<string>(removeImagesExtrasCabin ? id : "");

  const allowRemove = () => {
    return !!onRemove && (isMobile || isTouchEnabled || hover === id);
  };

  const generateImageUrl = (url: string | undefined) => {
    if (!!url) {
      return generateResponsiveImgUrlFromCloudflare(url, "xs");
    }
  };

  return (
    <div className={classes.basketItemContainer}>
      <Grid
        container
        className={classNames(classes.basketItem, {
          [classes.removeCabinImage]: removeImagesExtrasCabin,
        })}
        onMouseEnter={() => setHover(id)}
        onMouseLeave={() => {
          setHover(removeImagesExtrasCabin ? id : "");
        }}
        spacing={removeImagesExtrasCabin ? 1 : 0}
      >
        <>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs>
                {!!title && (
                  <Typography className={classes.title}>
                    <strong>{title}</strong>
                  </Typography>
                )}
                {!!subtitle && (
                  <Typography className={classes.title}>{subtitle}</Typography>
                )}
              </Grid>
              {!!imageUrl || !!onRemove ? (
                <Grid item>
                  <BasketItemImage
                    imageUrl={generateImageUrl(imageUrl)}
                    handleRemove={
                      allowRemove() && !!onRemove ? () => onRemove() : undefined
                    }
                    removeImagesExtrasCabin={removeImagesExtrasCabin}
                  />
                </Grid>
              ) : (
                <Grid item>
                  {(preDiscountValue ?? 0) > (value ?? 0) && (
                    <Typography className={classes.preDiscountValue}>
                      <FHCurrency>{preDiscountValue}</FHCurrency>
                    </Typography>
                  )}
                  <Typography className={classes.value}>
                    <strong>
                      <FHCurrency>{value ?? 0}</FHCurrency>
                    </strong>
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs>
                <Typography className={classes.description}>
                  {description}
                </Typography>
              </Grid>
              {(!!imageUrl || !!onRemove) && (
                <Grid item>
                  {(preDiscountValue ?? 0) > (value ?? 0) && (
                    <Typography className={classes.preDiscountValue}>
                      <FHCurrency>{preDiscountValue}</FHCurrency>
                    </Typography>
                  )}
                  <Typography className={classes.value}>
                    <strong>
                      <FHCurrency>{value ?? 0}</FHCurrency>
                    </strong>
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
          {!!tag && (
            <Grid item xs={12}>
              <Typography className={classes.tag}>
                <strong>{tag}</strong>
              </Typography>
            </Grid>
          )}
        </>
      </Grid>
    </div>
  );
};
