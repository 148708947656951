import React, { PropsWithChildren } from "react";
import { makeStyles } from "@mui/styles";
import { Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import classNames from "classnames";

const useStyles = (showRemove: boolean, imageUrl?: string) =>
  makeStyles((theme) => ({
    container: {
      background: !!imageUrl
        ? showRemove
          ? `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),url(${imageUrl})`
          : `url(${imageUrl})`
        : showRemove
          ? theme.palette.text.disabled
          : "none",
      width: 50,
      height: 50,
      borderRadius: "50%",
      backgroundSize: "cover",
      backgroundPosition: "center",
      marginBottom: theme.spacing(1),
    },
    removeButton: {
      color: theme.palette.common.white,
      borderColor: theme.palette.divider,
      border: "2px solid",
      borderRadius: theme.shape.borderRadius * 2,
      minWidth: "auto",
      padding: theme.spacing(0.2),
      margin: theme.spacing(0.9, 0, 0, 0.9),
    },
    removeImagesContainer: {
      background: "transparent",
      width: 32,
      height: 32,
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      marginBottom: 0,
      "& button": {
        margin: 0,
        borderColor: theme.palette.info.dark,
        color: theme.palette.info.dark,
        "&:hover": {
          width: 28,
          height: 28,
          border: "2px solid",
          borderColor: theme.palette.primary.light,
          color: theme.palette.primary.light,
        },
      },
    },
  }))();

type BasketItemImageProps = {
  imageUrl?: string;
  handleRemove?: () => void;
  removeImagesExtrasCabin?: boolean;
};

export const BasketItemImage: React.FC<BasketItemImageProps> = ({
  imageUrl,
  handleRemove,
  removeImagesExtrasCabin,
}: PropsWithChildren<BasketItemImageProps>) => {
  const classes = useStyles(!!handleRemove, imageUrl);

  return (
    <div
      className={classNames(classes.container, {
        [classes.removeImagesContainer]: removeImagesExtrasCabin,
      })}
    >
      {!!handleRemove && (
        <Button
          variant="outlined"
          className={classes.removeButton}
          onClick={handleRemove}
        >
          <CloseIcon />
        </Button>
      )}
    </div>
  );
};
