import React, { PropsWithChildren } from "react";
import { makeStyles } from "@mui/styles";
import { FHButton } from "../FHButton";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 2),
  },
  underLineButton: {
    textDecoration: "underline",
    "&:hover": {
      textDecoration: "underline",
    },
    "&:focus": {
      textDecoration: "underline !important",
    },
  },
  underLineButtonDisabled: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
    "&:focus": {
      textDecoration: "none !important",
    },
  },
}));

type BasketContinueButtonProps = {
  label?: string;
  loading?: boolean;
  onClick?: () => void;
  disableContinueCTA?: boolean;
  applyUnderliningStylingCheckoutCTAs?: boolean;
};

export const ContinueButton: React.FC<BasketContinueButtonProps> = ({
  label = "Continue",
  loading = false,
  onClick,
  disableContinueCTA = false,
  applyUnderliningStylingCheckoutCTAs,
}: PropsWithChildren<BasketContinueButtonProps>) => {
  const classes = useStyles();

  return (
    <>
      {!!onClick && !loading && (
        <div className={classNames(classes.root)}>
          <FHButton
            fullWidth={true}
            onClick={onClick}
            disabled={disableContinueCTA}
            classes={{
              root:
                applyUnderliningStylingCheckoutCTAs && classes.underLineButton,
              disabled:
                applyUnderliningStylingCheckoutCTAs &&
                classes.underLineButtonDisabled,
            }}
          >
            {label}
          </FHButton>
        </div>
      )}
    </>
  );
};
